<template>
    <div>
    <div class="sub-title">种植日志</div>
        <el-button type="primary" style="min-width: 88px;" v-if="tableData.length < 4" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="plantingDate" label="日期"> </el-table-column>
            <el-table-column prop="newFarmerName" label="新农人"> </el-table-column>
            <el-table-column prop="plantingEvent" label="事件"> </el-table-column>
            <el-table-column prop="plantingEventPhotoUrl1" label="日志图片1">
                <template slot-scope="scope">
                    <img class="log-img" :src="scope.row.plantingEventPhotoUrl1" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="plantingEventPhotoUrl2" label="日志图片2">
                <template slot-scope="scope">
                    <img class="log-img" :src="scope.row.plantingEventPhotoUrl2" alt="">
                </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
                    <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->
        <el-dialog :title="title" class="pop" width="500px" :visible.sync="dialog">
            <el-form  label-width="150px">
                <el-form-item label="选择日期">
                    <el-date-picker
                        style="width: 100%;"
                        v-model="form.plantingDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="新农人">
                    <el-input v-model="form.newFarmerName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="事件">
                    <el-input v-model="form.plantingEvent" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="日志图片1">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.plantingEventPhotoUrl1" :src="form.plantingEventPhotoUrl1" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
                <el-form-item label="日志图片2">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.plantingEventPhotoUrl2" :src="form.plantingEventPhotoUrl2" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
                plantingDate:'',
                newFarmerName: '',
                plantingEvent: '',
                plantingEventPhotoUrl1: '',
                plantingEventPhotoUrl2: ''
            }
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询
            this.qa.datascreen11PlantingLogQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.dialog = true
        },
        add() {
            this.title = '新增'
            this.form = {
                plantingDate:'',
                newFarmerName: '',
                plantingEvent: '',
                plantingEventPhotoUrl1: '',
                plantingEventPhotoUrl2: ''
            }
            this.dialog = true
        },
        addSubmit() {
            if(this.title == '新增') {
                this.qa.datascreen11PlantingLogDoInsert(this.form).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }else {
                //修改
                this.qa.datascreen11PlantingLogDoUpdate(this.form).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }
        },
        remove(id) {
            this.qa.datascreen11PlantingLogDoDeleteById({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        },
        handleAvatarSuccess1(res) {
            this.form.plantingEventPhotoUrl1 = res.data.url
            this.$forceUpdate()
        },
        handleAvatarSuccess2(res) {
            this.form.plantingEventPhotoUrl2 = res.data.url
            this.$forceUpdate()
        },
        beforeAvatarUploadPice(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10;
            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 10MB!');
            }
            return  isLt2M;
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
    .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
  .log-img {
    width: 150px;
    height: 162px;
  }
</style>