<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="190px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="小米种植面积（亩）">
                        <el-input v-model="form.milletPlantingArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="小米产量（吨）">
                        <el-input v-model="form.milletOutput"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="示范基地">
                        <el-input v-model="form.demonstrationBase"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="种植总面积（亩）">
                        <el-input v-model="form.jidiBigdataTotalPlantingArea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="6">
                    <el-form-item label="种植地块数（块）">
                        <el-input v-model="form.jidiBigdataNumberOfPlantingPlots"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="种植品种数（类）">
                        <el-input v-model="form.jidiBigdataNumberOfPlantingVarieties"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="技术人员数（人）">
                        <el-input v-model="form.jidiBigdataNumberOfTechnicians"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="管理人员数（人）">
                        <el-input v-model="form.jidiBigdataNumberOfManagementPersonnel"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="农机具台（套）">
                        <el-input v-model="form.jidiBigdataNumberOfAgriculturalMachinery"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <div class="sub-title">种植面积</div>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.plantingAreaAttr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.plantingAreaValue1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.plantingAreaUnit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.plantingAreaAttr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.plantingAreaValue2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.plantingAreaUnit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.plantingAreaAttr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.plantingAreaValue3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.plantingAreaUnit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <div class="sub-title">水肥情况</div>
            </el-row>
            <el-row>
  
                <el-col :span="6">
                    <el-form-item label="用水量亩均数量（吨）">
                        <el-input v-model="form.waterStatisticsAvgMu"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="用水量总量（吨）">
                        <el-input v-model="form.waterStatisticsTotalVal"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>

                <el-col :span="6">
                    <el-form-item label="用肥量亩均数量（吨）">
                        <el-input v-model="form.fertilizerStatisticsAvgMu"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="用肥量总量（吨）">
                        <el-input v-model="form.fertilizerStatisticsTotalVal"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="一升小米品种介绍">
                        <el-input style="width: 550px;" type="textarea" v-model="form.milletVarietiesDesc"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row>
                <div class="sub-title">基地实时画面</div>
            </el-row>
            <el-row>
                <el-form-item label="画面名称1">
                    <el-input v-model="form.jidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称2">
                    <el-input v-model="form.jidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="地图">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccessmap"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.vedio3dUrl" :src="form.vedio3dUrl" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="小米检测报告1">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.nutritionCompositionTestReportUrl1" :src="form.nutritionCompositionTestReportUrl1" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="小米检测报告2">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.nutritionCompositionTestReportUrl2" :src="form.nutritionCompositionTestReportUrl2" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="土壤监测报告1">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess3"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.soilMonitoringReportUrl1" :src="form.soilMonitoringReportUrl1" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="土壤监测报告2">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess4"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.soilMonitoringReportUrl2" :src="form.soilMonitoringReportUrl2" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
            <el-row class="">
                <el-form-item label="二维码地址">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccessCode"
                        :before-upload="beforeAvatarUploadPice">
                        <img v-if="form.milletQrcodeUrl" :src="form.milletQrcodeUrl" class="avatar">
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                            <div class="ta_c hint mt5 mb5">支持jpg/png格式 </div>
                            <div class="ta_c hint"> RGB模式，不超过10M</div>
                        </div>
                    </el-upload>
                    <!-- <el-input style="width: 700px" v-model="basicInformation.qiranYikuangdanQrcodeUrl"></el-input> -->
                </el-form-item>
            </el-row>
        </el-form>
        
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: ''
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen11LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen11DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        handleAvatarSuccessmap(res) {
            this.form.vedio3dUrl = res.data.url
        },
        handleAvatarSuccess1(res) {
            this.form.nutritionCompositionTestReportUrl1 = res.data.url
        },
        handleAvatarSuccess2(res) {
            this.form.nutritionCompositionTestReportUrl2 = res.data.url
        },
        handleAvatarSuccess3(res) {
            this.form.soilMonitoringReportUrl1 = res.data.url
        },
        handleAvatarSuccess4(res) {
            this.form.soilMonitoringReportUrl2 = res.data.url
        },
        handleAvatarSuccessCode(res) {
            this.form.milletQrcodeUrl = res.data.url
        },
        beforeAvatarUploadPice(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10;
            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 10MB!');
            }
            return  isLt2M;
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            //const isJPG = file.type === 'image/jpeg';
            //const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>